import "./style.scss";

import { Header } from "common/components";
import UrlHelper from "helpers/url-helper";
import SearchHelper from "lib/helpers/search-helper";
import { ISearchResult, ISearchResultsOutput, RelevanceType } from "lib/interfaces/search";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { useAppContext } from "../../../AppContext";
import ClickstreamHelper, { FeatureMetric, Language } from "../../../lib/helpers/clickstream-helper";
import LoadingScreen from "../../../modules/components/LoadingScreen";

const SearchResultsPage: FC = () => {
  const [results, setResults] = useState<ISearchResultsOutput>();
  const [loading, setLoading] = useState(false);
  const { clickStreamUrls } = useAppContext();
  const { search } = useLocation();

  useEffect(() => {
    fetchSearchResults();
  }, []);

  useEffect(() => {
    if (!results) {
      return;
    }

    const searchQuery = new URLSearchParams(window.location.search).get("keywords") ?? "";
    const resultsNumber = results.results?.length.toString() ?? "0";
    ClickstreamHelper.createFeatureMetric({
      feature: FeatureMetric.NUMBER_OF_SEARCH_RESULTS,
      language: Language.EN,
      searchQuery,
      resultsNumber,
      uri: `${searchQuery}/${resultsNumber}`,
      ...clickStreamUrls,
    });
  }, [results]);

  const fetchSearchResults = async () => {
    setLoading(true);
    const keywords = new URLSearchParams(search).get("keywords") ?? "";

    if (!keywords.trim().length) {
      // If keywords only contain all whitespaces, set results to empty array
      setLoading(false);
      setResults({
        results: [],
        faq: { type: "", path: "", id: 0, content: "", feedbackToken: "" },
        nextToken: "",
        queryId: "",
      });
      return;
    }

    try {
      const results = await SearchHelper.getSearchResults({ keywords: keywords });
      setLoading(false);
      setResults(results);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setResults(undefined);
    }
  };
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
      </Helmet>
      <div className="search-result-list">
        <LoadingScreen show={loading} />
        <Header>Search results for: {new URLSearchParams(window.location.search).get("keywords")}</Header>
        <br />
        {results && <SearchResults queryId={results.queryId} results={results?.results} />}
      </div>
    </>
  );
};

type props = {
  queryId: string;
  results: ISearchResult[] | undefined;
};

const SearchResults = ({ queryId, results }: props) => {
  const { clickStreamUrls } = useAppContext();
  if (!results || results.length == 0) {
    return <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>No results found</h3>;
  } else {
    return (
      <>
        {results!.map((result: ISearchResult, index) => {
          const handleResultClick = async () => {
            // Record kendra feedback for CTR (Click through rate) analytics
            SearchHelper.submitSearchFeedback({
              tenant: "kb",
              queryId: queryId,
              resultId: result.id,
              relevanceValue: RelevanceType.RELEVANT,
            });

            // Record clickstream feature metrics
            ClickstreamHelper.createFeatureMetric({
              feature: `${FeatureMetric.SEARCH_RESULT}_${index}`,
              language: Language.EN,
              uri: result.articlePath,
              ...clickStreamUrls,
            });
          };
          return (
            <article key={index} title={result.articleTitle} className="search-result-item">
              <header className="search-result-title">
                <Link to={result.articlePath} onClick={handleResultClick}>
                  {result.articleTitle}
                </Link>
              </header>
              <p className="search-result-overview">{result.overview}</p>
            </article>
          );
        })}
      </>
    );
  }
};

export default SearchResultsPage;
