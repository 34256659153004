import React, { FC } from "react";

const RequestUpdateButton: FC = () => {
  return (
    <a
      target="_blank"
      className="header-button"
      href="https://w.amazon.com/bin/view/ITservices/Content/ITSE-KB/Intake/Update_Existing_Article/"
      rel="noreferrer"
    >
      Request update
    </a>
  );
};

export default RequestUpdateButton;
