import React, { FC } from "react";

const HeaderV2: FC = () => {
    return (
        <>
            <p>Hello World!</p>
        </>
    )
}

export default HeaderV2;