import { API_SEARCH_PATH, API_SEARCH_SUGGESTIONS_PATH, API_SUBMIT_SEARCH_FEEDBACK_PATH } from "config/route-constants";

import { MAIN_PAGE_TITLE } from "../../config/constants";
import UrlHelper from "../../helpers/url-helper";
import HttpClient from "../clients/HttpClient";
import {
  ISearchResultsInput,
  ISearchResultsOutput,
  ISearchSuggestionsInput,
  ISearchSuggestionsOutput,
  ISubmitSearchFeedbackInput,
} from "../interfaces/search";

export default class SearchHelper {
  static basePath: string = `${UrlHelper.getApiUrl(UrlHelper.getStage())}`;

  static getSearchSuggestions = async (
    input: ISearchSuggestionsInput
  ): Promise<ISearchSuggestionsOutput | undefined> => {
    const path = `keywords=${input.keywords}`;

    const url = `${this.basePath}${API_SEARCH_SUGGESTIONS_PATH}?${path}`;
    try {
      const response = await HttpClient.getData(url);
      const json = response.json();

      if (response.redirected === true) {
        // update the url in the browser
        window.history.pushState("", MAIN_PAGE_TITLE, this.extractPathFromUrl(response.url));
      }

      if (response.ok) {
        //status in the range 200-299
        return json;
      } else if (response.status === 404 || response.status === 400) {
        return undefined;
      } else {
        return undefined;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  static getSearchResults = async (input: ISearchResultsInput): Promise<ISearchResultsOutput | undefined> => {
    const path = `keywords=${input.keywords}&filters=ITPortal`;

    const url = `${this.basePath}${API_SEARCH_PATH}?${path}`;
    try {
      const response = await HttpClient.getData(url);
      const json = response.json();

      if (response.redirected === true) {
        // update the url in the browser
        window.history.pushState("", MAIN_PAGE_TITLE, this.extractPathFromUrl(response.url));
      }

      if (response.ok) {
        //status in the range 200-299
        return json;
      } else if (response.status === 404 || response.status === 400) {
        return undefined;
      } else {
        return undefined;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  static submitSearchFeedback = async (input: ISubmitSearchFeedbackInput): Promise<void> => {
    const url = `${this.basePath}${API_SUBMIT_SEARCH_FEEDBACK_PATH}`;
    try {
      await HttpClient.postData<ISubmitSearchFeedbackInput>(url, input);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  private static extractPathFromUrl(responseUrl: string): string {
    const url = new URL(responseUrl);
    return url.pathname.replace(`${API_SEARCH_PATH}`, "");
  }
}
