/** Search suggestions - Input */
export interface ISearchSuggestionsInput {
  keywords: string;
}

/** Search suggestions - Output */
export interface ISearchSuggestionsOutput {
  suggestions: ISearchSuggestion[];
}

/** Search suggestion */
export interface ISearchSuggestion {
  text: string;
  highlights?: ISearchSuggestionHighlights[];
}

/** Highlighed area in the text */
export interface ISearchSuggestionHighlights {
  beginOffset: number;
  endOffset: number;
}

/** Search results - Input */
export interface ISearchResultsInput {
  keywords: string;
}

/** Search results - Input */
export interface ISearchResultsOutput {
  faq: ISearchResultsFaq;
  results: ISearchResult[];
  nextToken: string;
  queryId: string;
}

export interface ISearchResultsFaq {
  type: string;
  path: string;
  id: number;
  content: string;
  feedbackToken: string;
}

export interface ISearchResult {
  articleTitle: string;
  overview: string;
  articlePath: string;
  createdAt: string;
  updatedAt: string;
  categories: string[];
  feedbackToken: string;
  id: string;
}

/** Submit Search Feedback - Input */
export interface ISubmitSearchFeedbackInput {
  tenant: string;
  queryId: string;
  resultId: string;
  relevanceValue: RelevanceType;
}

export enum RelevanceType {
  RELEVANT = "RELEVANT",
  NOT_RELEVANT = "NOT_RELEVANT",
}
